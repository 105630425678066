<script setup lang="ts">
import LoadingBar from '@/ai/components/LoadingBar.vue';
import { setup } from '@/ai/components/chatWindow.setup';
import UserMessage from '@/ai/components/UserMessage.vue';
import BotMessage from '@/ai/components/BotMessage.vue';
import { RESIZE_DIRECTION } from '@/ai/utils/defined';

const {
  size,
  startResize,
  userInput,
  isChatWindowOpen,
  toggleChat,
  sendMessage,
  isLoading,
  allMessages,
  handleMessageClick,
  container,
  onEnter,
  position,
  startDrag,
  stopDrag,
  handleScroll,

  t,
} = setup();
</script>

<template>
  <div>
    <div
      v-show="isChatWindowOpen"
      class="chat-window__wrapper"
      :style="{
        top: position.top + 'px',
        left: position.left + 'px',
        width: size.width + 'px',
        height: size.height + 'px',
      }"
    >
      <div
        ref="container"
        class="chat-window__content"
        @mousedown="startDrag"
        @mouseup="stopDrag"
        @scroll="handleScroll"
      >
        <button
          class="chat-window__close-btn"
          @click="toggleChat"
        >
          <ev-icon class="icon-close" />
        </button>
        <div
          v-for="(message, idx) in allMessages"
          :key="idx"
          class="chat-window__content__message"
        >
          <user-message
            v-if="message.sender !== 'chatbot'"
            :message="message"
          />
          <bot-message
            v-else
            :message="message"
            @message-click="handleMessageClick"
          />
        </div>
        <loading-bar v-if="isLoading" />
      </div>

      <div class="chat-window__input-section">
        <input
          v-model="userInput"
          :disabled="isLoading"
          placeholder="메시지를 입력"
          class="chat-window__input-section__input"
          @keyup.enter="onEnter"
        />
        <ev-icon
          class="icon-arrow-circle-up"
          @click="sendMessage"
        />
      </div>

      <div class="chat-window__description">
        {{ t('DESC.AI.CHAT_AVAILABLE') }}
      </div>
      <div
        v-for="direction in RESIZE_DIRECTION"
        :key="direction"
        class="resize-handle"
        :class="direction"
        @mousedown="(e) => startResize(e, direction)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.chat-window {
  &__wrapper {
    position: fixed;
    z-index: $on-chat-window;
    flex-direction: column;
    border-radius: 8px;
    background: var(--color-gray-08-01);
    box-shadow:
      -10px 20px 40px -7px var(--ai-chat-window-shadow-color),
      19px -8px 20px -20px var(--ai-chat-window-shadow-color);
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    height: calc(100% - 120px);
    padding: 0 12px;
    overflow-y: auto;
    border: 1px solid transparent;
    border-radius: 8px;
  }

  &__close-btn {
    display: flex;
    box-sizing: border-box;
    position: sticky;
    top: 12px;
    left: calc(100% - 24px);
    z-index: 10;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    background: none;

    &:hover {
      border: 1px solid var(--color-blue-05);
    }
    &:active {
      border: 1px solid var(--color-blue-05);
      background-color: var(--color-blue-09-01);
    }

    .icon-close {
      background-color: var(--color-gray-02-10);
    }
  }

  &__loading-bar {
    width: 100%;
    height: auto;
  }

  &__input-section {
    display: flex;
    padding: 10px;
    border-radius: 12px;

    &__input {
      flex-grow: 1;
      height: 42px;
      padding: 10px;
      padding-right: 40px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-image: linear-gradient(var(--color-gray-09-00), var(--color-gray-09-00)),
        linear-gradient(180deg, #3c8eff 0%, #6c4fff 100%);
      background-clip: padding-box, border-box;
      background-origin: border-box;
      &:focus {
        outline: none;
        color: var(--main-font-color);
      }
    }
    .icon-arrow-circle-up {
      position: absolute;
      right: 20px;
      width: 28px;
      height: 42px;
      cursor: pointer;
      background: linear-gradient(135deg, #3c8eff 8.57%, #6c4fff 75%);
    }
  }

  &__resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    cursor: se-resize;
  }

  &__description {
    flex-shrink: 0;
    padding: 0 16px;
    overflow: hidden;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-gray-01-11);
    white-space: pre-line;
  }
}
.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: transparent;

  &.top {
    top: -5px;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: ns-resize;
  }

  &.bottom {
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: ns-resize;
  }

  &.left {
    top: 0;
    left: -5px;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
  }

  &.right {
    top: 0;
    right: -5px;
    width: 5px;
    height: 100%;
    cursor: ew-resize;
    pointer-events: auto;
  }

  &.top-left {
    top: -5px;
    left: -5px;
    width: 10px;
    height: 10px;
    cursor: nwse-resize;
  }

  &.top-right {
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    cursor: nesw-resize;
  }

  &.bottom-left {
    bottom: -5px;
    left: -5px;
    width: 10px;
    height: 10px;
    cursor: nesw-resize;
  }

  &.bottom-right {
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    cursor: nwse-resize;
  }
}
</style>
