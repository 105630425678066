import { defineStore } from 'pinia';
import { ref, computed, onMounted } from 'vue';
import { ChatState, Message } from '@/ai/utils/types';
import axios from 'axios';
import { store } from '@/common/store';
import { DEFAULT_CHAT_STATE } from '@/ai/utils/defined';

const getDefaultChatPosition = (): ChatState => {
  const storedState = localStorage.getItem('chatState');
  return storedState ? JSON.parse(storedState) : DEFAULT_CHAT_STATE;
};

export const useChatStore = defineStore('chat', () => {
  const chatPosition = ref<ChatState>(getDefaultChatPosition());
  const isChatWindowOpen = ref<boolean>(false);
  const allMessages = ref<Message[]>([]);
  const isLoading = ref<boolean>(false);

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const setChatPosition = (newPosition: Partial<ChatState>) => {
    chatPosition.value = {
      ...chatPosition.value,
      ...newPosition,
    };
    localStorage.setItem('chatState', JSON.stringify(chatPosition.value));
  };

  const openChat = () => {
    isChatWindowOpen.value = true;
  };

  const closeChat = () => {
    isChatWindowOpen.value = false;
  };

  const toggleChat = () => {
    isChatWindowOpen.value = !isChatWindowOpen.value;
  };

  const addMessage = (messages: Message | Message[]) => {
    if (Array.isArray(messages)) {
      allMessages.value.push(...messages);
    } else {
      allMessages.value.push(messages);
    }
  };

  const clearMessages = () => {
    allMessages.value = [];
  };

  const getChatMessage = async (lastTime: string | null) => {
    try {
      isLoading.value = true;
      const params = {
        userId: String(userInfo.value?.userId ?? -1),
        roomId: 0,
        ...(lastTime && allMessages.value.length > 0 && { lastTime }),
      };

      const { data } = await axios.get('/ai-api/v1/message', { params });

      if (data?.data) {
        const messages = data.data;
        const filteredMessages = lastTime
          ? messages.filter((message) => message.sender === 'chatbot')
          : messages;

        addMessage(filteredMessages);
      }
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    chatPosition.value = getDefaultChatPosition();
  });

  return {
    isChatWindowOpen,
    allMessages,
    isLoading,
    openChat,
    closeChat,
    toggleChat,
    addMessage,
    clearMessages,
    getChatMessage,
    setChatPosition,
    chatPosition,
  };
});
