import { ref, reactive, WritableComputedRef, isRef } from 'vue';
import { Position, Size } from '@/ai/utils/types';
import { MIN_WIDTH, MIN_HEIGHT, MAX_WIDTH } from '@/ai/utils/defined';

const calculatePosition = ({
  elementSize,
  position,
}: {
  elementSize: Size;
  position: Position;
}): Position => {
  const { innerWidth, innerHeight } = window;

  const top = Math.max(0, Math.min(innerHeight - elementSize.height, position.top));
  const left = Math.max(0, Math.min(innerWidth - elementSize.width, position.left));

  return { top, left };
};

export const useDrag = ({
  size,
  position,
}: {
  size: WritableComputedRef<Size> | Size;
  position: WritableComputedRef<Position>;
}) => {
  const dragStart = reactive({ x: 0, y: 0 });
  const isDragging = ref(false);

  const getElementSize = (): Size => {
    return isRef(size) ? size.value : size;
  };

  const onDrag = (event: PointerEvent) => {
    const deltaX = event.clientX - dragStart.x;
    const deltaY = event.clientY - dragStart.y;

    const newPosition = {
      top: position.value.top + deltaY,
      left: position.value.left + deltaX,
    };

    position.value = calculatePosition({
      elementSize: getElementSize(),
      position: newPosition,
    });

    dragStart.x = event.clientX;
    dragStart.y = event.clientY;
  };

  const stopDrag = () => {
    if (isDragging.value) {
      document.removeEventListener('pointermove', onDrag);
      document.removeEventListener('pointerup', stopDrag);
      isDragging.value = false;
    }
  };

  const startDrag = (event: PointerEvent) => {
    if (isDragging.value) return;
    isDragging.value = true;

    dragStart.x = event.clientX;
    dragStart.y = event.clientY;

    document.addEventListener('pointermove', onDrag);
    document.addEventListener('pointerup', stopDrag);
  };

  return {
    startDrag,
    stopDrag,
  };
};

export const useResize = ({
  size,
  position,
}: {
  size: WritableComputedRef<Size>;
  position: WritableComputedRef<Position>;
}) => {
  const direction = ref<string>('');
  const dragStart = reactive({ x: 0, y: 0 });
  const isResizing = ref(false);
  let pendingSize = { width: 0, height: 0 };
  let pendingPosition = { top: 0, left: 0 };
  let animationFrame: number | null = null;

  const onResize = (event: PointerEvent) => {
    const deltaX = event.clientX - dragStart.x;
    const deltaY = event.clientY - dragStart.y;

    pendingSize = { ...size.value };
    pendingPosition = { ...position.value };

    if (direction.value.includes('right')) {
      pendingSize.width = Math.min(Math.max(MIN_WIDTH, size.value.width + deltaX), MAX_WIDTH);
    }

    if (direction.value.includes('left')) {
      const potentialWidth = size.value.width - deltaX;
      if (potentialWidth >= MIN_WIDTH && potentialWidth <= MAX_WIDTH) {
        pendingSize.width = potentialWidth;
        pendingPosition.left += deltaX;
      }
    }

    if (direction.value.includes('top')) {
      const potentialHeight = size.value.height - deltaY;
      if (potentialHeight >= MIN_HEIGHT && potentialHeight <= window.innerHeight) {
        pendingSize.height = potentialHeight;
        pendingPosition.top += deltaY;
      }
    }

    if (direction.value.includes('bottom')) {
      pendingSize.height = Math.min(
        Math.max(MIN_HEIGHT, size.value.height + deltaY),
        window.innerHeight - position.value.top,
      );
    }

    if (!animationFrame) {
      animationFrame = requestAnimationFrame(() => {
        size.value = { ...pendingSize };
        position.value = calculatePosition({
          elementSize: pendingSize,
          position: pendingPosition,
        });

        animationFrame = null;
      });
    }

    dragStart.x = event.clientX;
    dragStart.y = event.clientY;
  };

  const stopResize = () => {
    if (isResizing.value) {
      document.removeEventListener('pointermove', onResize);
      document.removeEventListener('pointerup', stopResize);
      isResizing.value = false;
    }
  };

  const startResize = (event: PointerEvent, dir: string) => {
    event.preventDefault();
    if (!['top', 'bottom', 'left', 'right'].some((d) => dir.includes(d))) return;
    if (isResizing.value) return;

    direction.value = dir;
    dragStart.x = event.clientX;
    dragStart.y = event.clientY;
    isResizing.value = true;

    document.addEventListener('pointermove', onResize);
    document.addEventListener('pointerup', stopResize);
  };

  return {
    startResize,
    stopResize,
  };
};
