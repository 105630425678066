import { computed, ref, WritableComputedRef } from 'vue';
import { useDrag } from '@/ai/utils/utils';
import { Position } from '@/ai/utils/types';
import { useChatStore } from '../store/chat';

export const setup = () => {
  const chatStore = useChatStore();
  const isChatWindowOpen = computed(() => chatStore.isChatWindowOpen);

  const position: WritableComputedRef<Position> = computed<Position>({
    get: () => ({ ...chatStore.chatPosition.button }),
    set: (newPosition: Position) => {
      chatStore.setChatPosition({ button: newPosition });
    },
  });

  const { startDrag, stopDrag } = useDrag({
    size: { width: 60, height: 60 },
    position,
  });

  const previousPosition = ref<Position>({ ...position.value });

  const onMouseDown = (e: MouseEvent) => {
    previousPosition.value = { ...position.value };
    startDrag(e);
  };

  const onMouseUp = () => {
    stopDrag();

    const hasMoved =
      previousPosition.value.top !== position.value.top ||
      previousPosition.value.left !== position.value.left;

    if (!hasMoved) {
      chatStore.toggleChat();
    }
  };

  return {
    position,
    onMouseDown,
    onMouseUp,
    isChatWindowOpen,
  };
};
