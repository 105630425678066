import { ChatState } from '@/ai/utils/types';

export const ICON_TYPE = ['grid2', 'outlined-line-chart', 'settings', 'application-bold'];

export const GREETING_COLOR = [
  'var(--ai-chat-bot-greeting-icon-color-primary)',
  '#F59E0B',
  '#3B82F6',
  '#10B981',
];
export const GREETING_HOVER_COLOR = [
  'var(--ai-chat-bot-greeting-font-color-primary)',
  'var(--ai-chat-bot-greeting-font-color-secondary)',
  'var(--ai-chat-bot-greeting-font-color-tertiary)',
  'var(--ai-chat-bot-greeting-font-color-quaternary)',
];

export const DEFAULT_CHAT_STATE: ChatState = {
  button: { top: window.innerHeight - 80, left: window.innerWidth - 80 },
  window: { top: window.innerHeight - 800, left: window.innerWidth - 560 },
  windowScroll: { scrollTop: 0 },
  windowSize: { width: 480, height: 720 },
};

export const RESIZE_DIRECTION = [
  'top',
  'right',
  'bottom',
  'left',
  'top-right',
  'top-left',
  'bottom-right',
  'bottom-left',
];

export const MIN_WIDTH = 480;
export const MIN_HEIGHT = 500;
export const MAX_WIDTH = 740;
