import { onMounted, onUnmounted, ref } from 'vue';
import { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '@/common/assets/ai/floating-bot.json';

export const setup = () => {
  const container = ref<HTMLDivElement | null>(null);
  let floatingBotAnimation: AnimationItem | null = null;

  onMounted(() => {
    if (container.value) {
      floatingBotAnimation = lottie.loadAnimation({
        container: container.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  });

  onUnmounted(() => {
    if (floatingBotAnimation) {
      floatingBotAnimation.destroy();
    }
  });
  return {
    container,
  };
};
