import { computed, onMounted, onUnmounted, ref, watch, WritableComputedRef, nextTick } from 'vue';
import { useDrag, useResize } from '@/ai/utils/utils';
import { Position, Size, ScrollPosition } from '@/ai/utils/types';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '@/common/assets/ai/chat-loading.json';
import { AnimationItem } from 'lottie-web';
import { store } from '@/common/store';
import axios from 'axios';
import { useInternational } from '@/common/locale';
import { useChatStore } from '../store/chat';

export const setup = () => {
  const { t } = useInternational();
  const chatStore = useChatStore();
  const container = ref<HTMLDivElement | null>(null);
  let loadingAnimation: AnimationItem | null = null;

  const userInput = ref<string>('');
  const isUserScrolling = ref(false);
  let scrollTimeout: number | null = null;

  const isChatWindowOpen = computed(() => chatStore.isChatWindowOpen);
  const allMessages = computed(() => chatStore.allMessages);
  const isLoading = computed(() => chatStore.isLoading);
  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const position: WritableComputedRef<Position> = computed<Position>({
    get: () => ({ ...chatStore.chatPosition.window }),
    set: (newPosition: Position) => {
      chatStore.setChatPosition({ window: newPosition });
    },
  });

  const size: WritableComputedRef<Size> = computed<Size>({
    get: () => ({ ...chatStore.chatPosition.windowSize }),
    set: (newSize: Size) => {
      chatStore.setChatPosition({ windowSize: newSize });
    },
  });

  const scrollPosition = computed<ScrollPosition>({
    get: () => ({ ...chatStore.chatPosition.windowScroll }),
    set: (newScroll: ScrollPosition) => {
      chatStore.setChatPosition({ windowScroll: newScroll });
    },
  });

  const { startResize } = useResize({ size, position });
  const { startDrag, stopDrag } = useDrag({ size, position });

  const postChatMessage = async (message: string) => {
    try {
      chatStore.addMessage({
        sender: String(userInfo.value?.userId ?? -1),
        message,
        messageType: 'text',
        resultType: '',
        result: '',
        imageUrl: '',
      });

      chatStore.isLoading = true;

      const response = await axios.post('/ai-api/v1/message', {
        userId: String(userInfo.value?.userId ?? -1),
        message,
        messageType: 'text',
      });

      await chatStore.getChatMessage(response.data.responseTime);
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = async () => {
    if (!userInput.value.trim()) {
      return;
    }
    await postChatMessage(userInput.value);
    userInput.value = '';
  };

  const toggleChat = () => {
    chatStore.toggleChat();
  };

  const handleMessageClick = async (message: string) => {
    await postChatMessage(message);
    userInput.value = '';
  };

  const onEnter = async (e: KeyboardEvent) => {
    e.preventDefault();
    await sendMessage();
  };

  const scrollToBottom = async () => {
    await nextTick();
    if (container.value && !isUserScrolling.value) {
      container.value.scrollTop = container.value.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (container.value) {
      scrollPosition.value = {
        scrollTop: container.value.scrollTop,
      };

      isUserScrolling.value = true;

      if (scrollTimeout) clearTimeout(scrollTimeout);
      scrollTimeout = window.setTimeout(() => {
        isUserScrolling.value = false;
      }, 1000);
    }
  };

  onMounted(async () => {
    await chatStore.getChatMessage(null);

    // await nextTick();

    if (container.value) {
      try {
        loadingAnimation = lottie.loadAnimation({
          container: container.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData,
        });
      } catch (e) {
        console.log(e);
      }

      const observer = new MutationObserver(async () => {
        await scrollToBottom();
      });

      observer.observe(container.value, {
        childList: true,
        subtree: true,
      });

      onUnmounted(() => {
        observer.disconnect();
      });
    }
  });

  watch(
    () => allMessages.value.length,
    async () => {
      await scrollToBottom();
    },
  );

  watch(isChatWindowOpen, async (isOpen) => {
    if (isOpen) {
      await nextTick();
      if (container.value) {
        container.value.scrollTop = scrollPosition.value.scrollTop || 0;
      }
    }
  });

  return {
    size,
    startResize,
    userInput,
    allMessages,
    isChatWindowOpen,
    toggleChat,
    sendMessage,
    container,
    isLoading,
    handleMessageClick,
    handleScroll,
    position,
    stopDrag,
    startDrag,
    t,
    onEnter,
  };
};
